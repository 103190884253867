@import '@core/scss/core.scss';
@import './assets/scss/styles';

.z-index9999999{
  z-index: 9999999!important;
}
.z-index99999{
  z-index: 99999!important;

  // &:has(app-menu) {
  //   z-index: 1045!important;
  // }

}

.z-index9999{
  z-index: 9999!important;
}

.max-content{
  width: max-content!important;
}

.flatpickr-calendar.open {
  z-index: 999999!important;
}


.pointer {
  cursor: pointer;
}

.doc-logo{
  max-height:70px;
}
.space-between{
  justify-content: space-between!important;
}

.inline{
    display: inline;
  }

  .custom-options-checkable .custom-option-item {
    width: 100%;
    cursor: pointer;
    border-radius: 0.42rem;
    color: #82868b;
    background-color: rgba(130, 134, 139, 0.06);
    border: 1px solid #ebe9f1; }
  
  .custom-options-checkable .custom-option-item .custom-option-item-title {
    color: #82868b; }


    .custom-option-item-check {
        position: absolute;
        clip: rect(0, 0, 0, 0); }
      
      .custom-option-item-check:checked + .custom-option-item {
        color: #7367f0;
        background-color: rgba(115, 103, 240, 0.12);
        border-color: #7367f0; }
      
      .custom-option-item-check:checked + .custom-option-item .custom-option-item-title {
        color: #7367f0; }

        .fw-bolder {
          font-weight: 600 !important;
      }


.btn-connect{
  border: 1px solid #d8d6de!important;
  background-color: transparent;
  color: #82868b;

  img{
    height: 20px;
    width: 20px;
  }
}

.btn-connect:hover {
  background-color: rgba(130, 134, 139, 0.04);
}


.btn-custom-secondary{
  background-color: #82878c1f;
}

.btn-custom-secondary:hover{
  background-color: #82878c3d;
}

.mr-22-ml{
  margin-left: -22px;
    margin-right: -22px;
}

.qte-dispo{
  margin: 0;
    font-size: 11px;
}

.padding-right-2{
  padding-right: 2rem;
}


.invalid-feedback-lot{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.857rem;
  color: #ea5455;
}




input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



.btn-label-danger {
  color: #ea5455;
  border-color: rgba(0,0,0,0);
  background: #fce4e4;
}

.btn-label-danger:hover {
  border-color: rgba(0,0,0,0) !important;
  background: #fad6d6 !important;
  color: #ea5455 !important;
}